import React from 'react'

import { cn } from 'common/utils/tailwind'

export const illustratedIcons = {
  advice: require('./illustrated-icons/advice.svg'),
  award: require('./illustrated-icons/award.svg'),
  baby: require('./illustrated-icons/baby.svg'),
  book: require('./illustrated-icons/book.svg'),
  bulb: require('./illustrated-icons/bulb.svg'),
  clinic: require('./illustrated-icons/clinic.svg'),
  consultation: require('./illustrated-icons/consultation.svg'),
  diagnosis: require('./illustrated-icons/diagnosis.svg'),
  genetic: require('./illustrated-icons/genetic.svg'),
  icsi: require('./illustrated-icons/icsi.svg'),
  imaging: require('./illustrated-icons/imaging.svg'),
  'in-vitro': require('./illustrated-icons/in-vitro.svg'),
  'intra-uterine': require('./illustrated-icons/intra-uterine.svg'),
  laboratory: require('./illustrated-icons/laboratory.svg'),
  location: require('./illustrated-icons/location.svg'),
  mask: require('./illustrated-icons/mask.svg'),
  medication: require('./illustrated-icons/medication.svg'),
  'more-options': require('./illustrated-icons/more-options.svg'),
  network: require('./illustrated-icons/network.svg'),
  'online-supporting': require('./illustrated-icons/online-supporting.svg'),
  'pregnancy-test': require('./illustrated-icons/pregnancy-test.svg'),
  rates: require('./illustrated-icons/rates.svg'),
  satisfaction: require('./illustrated-icons/satisfaction.svg'),
  science: require('./illustrated-icons/science.svg'),
  'social-freezing': require('./illustrated-icons/social-freezing.svg'),
  star: require('./illustrated-icons/star.svg'),
  support: require('./illustrated-icons/support.svg'),
  treatments: require('./illustrated-icons/treatments.svg'),
  university: require('./illustrated-icons/university.svg'),
}

export type IconOptions = keyof typeof illustratedIcons

export function isValidIllustratedIcon(
  iconName: unknown
): iconName is IconOptions {
  if (typeof iconName !== 'string' || !iconName) return false
  return iconName in illustratedIcons
}

export const getValidIllustratedIcon = (
  icon: unknown
): IconOptions | undefined => {
  if (isValidIllustratedIcon(icon)) {
    return icon
  } else return
}

export type IconProps = {
  icon: IconOptions | string | number
  className?: string
  iconClass?: string
  rectangleClass?: string
  rectangleColor?: 'blue' | 'yellow' | 'pink' | 'aqua'
}

export const rectangleColors = {
  blue: 'text-background-secondary-blue',
  yellow: 'text-background-secondary-yellow',
  pink: 'text-background-secondary-pink',
  aqua: 'text-background-secondary-aqua',
} as const

export const IllustratedIcon = ({
  icon,
  className,
  iconClass,
  rectangleClass,
  rectangleColor,
}: IconProps) => {
  const typedIllustratedIcon = getValidIllustratedIcon(icon)

  if (!typedIllustratedIcon) return null

  const IllustratedIconSVG = illustratedIcons[typedIllustratedIcon]?.default

  if (!IllustratedIconSVG) {
    console.warn(`Icon "${icon}" does not exist in icons.`)
    return null
  }

  return (
    <div className={cn('relative isolate size-11.5', className)}>
      {/* Rectangle */}
      <div
        className={cn(
          'size-8 absolute bottom-0 left-0 text-foreground/10',
          rectangleColor && rectangleColors[rectangleColor],
          rectangleClass
        )}
      >
        <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0 0H20.3636C26.7902 0 32 5.20978 32 11.6364V32H11.6364C5.20978 32 0 26.7902 0 20.3636V0Z"
            fill="currentColor"
          />
        </svg>
      </div>

      {/* Icon */}
      <div
        className={cn(
          'absolute right-0 top-0 stroke-foreground size-10.5',
          iconClass
        )}
      >
        <IllustratedIconSVG />
      </div>
    </div>
  )
}
